import React, { useState } from 'react';

const EmailPopup = () => {
    const [email, setEmail] = useState('');
    const [showModal, setShowModal] = useState(true); // Start with showModal true by default

    const handleSubmit = async (e) => {
        e.preventDefault();
        // API request to backend
        const response = await fetch('https://sazalis.com/save-email.php', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ email: email }),
        });
        const data = await response.json();
        if (data.success) {
            setShowModal(false);
            localStorage.setItem('hasVisited', 'true'); // Set hasVisited on successful email submission
        }
    };

    // Check if the user has already visited
    if (localStorage.getItem('hasVisited')) {
        return null;
    }

    if (!showModal) return null;

    return (
        <div style={{
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'rgba(0,0,0,0.75)',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            zIndex:1000
        }}>
            <div style={{
                backgroundImage: 'url("/re.png")',
                padding: '30px',
                paddingTop:'15px',
                borderRadius: '10px',
                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                width: '300px'
            }}>
                <h2 style={{ textAlign: 'center' }}>You can use this tool completely for free!</h2>
                <form onSubmit={handleSubmit} style={{textAlign:'center'}}>
                    <label style={{ display: 'block', marginBottom: '10px' }}>
                        Enter your email:
                        <input type="email" value={email} onChange={(e) => setEmail(e.target.value)} required style={{ width: '93%', padding: '8px', marginTop: '5px' }} />
                    </label>
                    <button type="submit" style={{ width: '100%', padding: '10px', background: '#007BFF', color: 'white', border: 'none', borderRadius: '5px', cursor: 'pointer' }}>
                        Submit
                    </button>
                </form>
            </div>
        </div>
    );
};

export default EmailPopup;
