import React, { useState, useRef, useCallback } from 'react';
import * as XLSX from 'xlsx';
import axios from 'axios';
import { useDropzone } from 'react-dropzone';
import EmailPopup from './EmailPopup'; // Adjust the path based on your file structure
import {
  AppBar,
  Toolbar,
  Button,
  CircularProgress,
  Container,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  LinearProgress,
  Paper,
  Box,
  FormControl,
  Snackbar,
  Alert, // Import Alert
} from '@mui/material';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import HomeIcon from '@mui/icons-material/Home';
import ExcelJS from 'exceljs';

import './App.css';

function App() {
  const [headers, setHeaders] = useState([]);
  const [selectedColumnIndex, setSelectedColumnIndex] = useState('');
  const [dataRows, setDataRows] = useState([]);
  const [extractedEmails, setExtractedEmails] = useState([]);
  const [progress, setProgress] = useState(0);
  const [fileName, setFileName] = useState('');
  const [loading, setLoading] = useState(false);
  const [showStoppedMessage, setShowStoppedMessage] = useState(false);
  const isStopped = useRef(false);

  // Add state variables for Snackbar
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');

  const [loadingMessage, setLoadingMessage] = useState('');

  const handleStartExtraction = () => {
    if (selectedColumnIndex === '') {
      setSnackbarMessage('Please choose URL column');
      setOpenSnackbar(true);
      return;
    }
    extractEmailsFromUrls();
  };

  const handleStopExtraction = () => {
    if (selectedColumnIndex === '') {
      setSnackbarMessage('Please choose URL column');
      setOpenSnackbar(true);
      return;
    }
    stopExtraction();
  };

  const handleExportToExcel = () => {
    if (selectedColumnIndex === '') {
      setSnackbarMessage('Please choose URL column');
      setOpenSnackbar(true);
      return;
    }
    exportToExcel();
  };


  const handleFileUpload = (file) => {
    // Define allowed MIME types for Excel files
    const allowedMimeTypes = [
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', // .xlsx
      'application/vnd.ms-excel', // .xls
    ];
  
    // Check if the uploaded file is an Excel file
    if (!allowedMimeTypes.includes(file.type)) {
      alert('Please upload a valid Excel file (.xls or .xlsx).');
      return;
    }
  
    // Reset state variables
    setSelectedColumnIndex('');  // Reset selected column
    setProgress(0);
    setLoading(false);
    setShowStoppedMessage(false);
    isStopped.current = false;
  
    const reader = new FileReader();
  

    setLoading(true);
    setLoadingMessage('Processing file...');

    reader.onload = (evt) => {
      const data = new Uint8Array(evt.target.result);
      const workbook = XLSX.read(data, { type: 'array' });
      const firstSheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[firstSheetName];
      const jsonSheet = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
  
      setHeaders(jsonSheet[0]);
  
      const normalizedRows = jsonSheet.slice(1).map((row) => {
        const completeRow = [...row];
        while (completeRow.length < jsonSheet[0].length) {
          completeRow.push('');
        }
        return completeRow;
      });
  
      setDataRows(normalizedRows);
      setExtractedEmails(normalizedRows.map(() => ({ email: '' })));
      setLoading(false);
      setLoadingMessage('');
    
    };
  
    setFileName(file.name);
    reader.readAsArrayBuffer(file);
  };
  

  const onDrop = useCallback((acceptedFiles) => {
    const file = acceptedFiles[0];
    if (file) {
      handleFileUpload(file);
    }
  }, []);


  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: {
      'application/vnd.ms-excel': ['.xls'],
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'],
    },
    onDrop: (acceptedFiles) => {
      if (acceptedFiles && acceptedFiles.length > 0) {
        handleFileUpload(acceptedFiles[0]);
      }
    },
  });
  
  const handleColumnSelection = (selectedColumn) => {
    if (selectedColumn) {
      const selectedColumnIndex = headers.indexOf(selectedColumn);
      if (selectedColumnIndex >= 0) {
        setSelectedColumnIndex(selectedColumnIndex);
      }
    }
  };

  const batchRequests = async (dataRows, batchSize, requestFn) => {
    for (let i = 0; i < dataRows.length; i += batchSize) {
      if (isStopped.current) {
        break;
      }

      const batch = dataRows.slice(i, i + batchSize).map(requestFn);

      try {
        const batchResults = await Promise.all(batch);

        setExtractedEmails((prevEmails) => {
          const updatedEmails = [...prevEmails];
          batchResults.forEach((result, index) => {
            updatedEmails[i + index] = { email: result.email };
          });
          return updatedEmails;
        });

        const progressPercentage = Math.min(((i + batchSize) / dataRows.length) * 100, 100);
        setProgress(progressPercentage.toFixed(2));

        // Remove the following lines
        // if (progressPercentage > 0) {
        //   setLoading(false);
        // }
      } catch (error) {
        console.error('Failed to process batch', error);
      }
    }
  };


  const extractEmail = async (row) => {
    const url = row[selectedColumnIndex];
    if (url) {
      try {
        const response = await axios.post(
          'https://azomas.com/extract_emails.php',
          JSON.stringify({ url }),
          { headers: { 'Content-Type': 'application/json' } }
        );
        const data = response.data;
        return { row, email: data.email };
      } catch (error) {
        console.error(`Failed to fetch URL: ${url}`, error);
        return { row, email: 'Failed to fetch email' };
      }
    }
    return { row, email: 'No URL' };
  };

  const extractEmailsFromUrls = async () => {
    isStopped.current = false;
    setProgress(0);
    setLoading(true);
    setLoadingMessage('Extraction has started');
    setShowStoppedMessage(false);

    await batchRequests(dataRows, 10, extractEmail);

    // Clear loading state after extraction
    setLoading(false);
    setLoadingMessage('');
  };

  const stopExtraction = () => {
    isStopped.current = true;
    setShowStoppedMessage(true); // Show the stopped message when extraction is stopped
    setLoading(false);
  };

  const exportToExcel = async () => {
    // Prepare data for export
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Extracted Data');

    // Set the width of the first column to approximately 300 pixels
    worksheet.getColumn(1).width = 35.14;

    // Add header row
    const headerRow = ['Extracted Email', ...headers];
    worksheet.addRow(headerRow);

    // Get the header row object
    const headerRowObject = worksheet.getRow(1);

    // Set the background color of the first header cell to green
    headerRowObject.getCell(1).fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: 'FF00FF00' }, // Green color in ARGB format
    };

    // Add data rows
    dataRows.forEach((row, rowIndex) => {
      const extractedEmail = extractedEmails[rowIndex]?.email || '';
      const rowData = [extractedEmail, ...row];
      const newRow = worksheet.addRow(rowData);

      // If there is an extracted email, set background color of the first cell
      if (extractedEmail) {
        newRow.getCell(1).fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: 'FFFFEB3B' }, // Yellow color
        };
      }
    });

    // Save workbook to a blob and trigger download
    const buf = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buf], { type: 'application/octet-stream' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = 'extracted_emails.xlsx';
    a.click();
    window.URL.revokeObjectURL(url);
  };



  return (
    <>
                <EmailPopup />
      <AppBar
        position="static"
        sx={{
          backgroundImage: 'url("/re.png")',
          backgroundPosition: 'center',
        }}
      >
        <Toolbar>
          <Box sx={{ flexGrow: 1, display: 'flex', justifyContent: 'center' }}>
            <img
              src="/logo_2.png"
              alt="Logo"
              style={{ width: '213px', height: '95px' }}
            />
          </Box>
        </Toolbar>
      </AppBar>

      <div className="container">
      
        <section className="hero">
          <h2>
            Stop Wasting Time Collecting Emails Manually. <br />
            Extract <u>Automaticall</u>y{' '}
            <span style={{ color: '#38b6ff' }}>Targeted Emails</span> from Your
            List of Target Websites <u>For Free</u>
          </h2>

          <p>
            Just gather website URLs, and let our tool extract thousands of emails
            for you automatically. Save hours of work and boost your campaigns
            effortlessly.
          </p>

          <ol
            style={{
              color: '#ffffff',
              margin: 'auto',
              marginBottom: '17px',
              fontSize: '18px',
              fontWeight: 700,
              padding: '15px',
              listStylePosition:'inside'
            }}
          >
            <li style={{ marginBottom: '7px' }}>Upload Excel File</li>
            <li style={{ marginBottom: '7px' }}>Choose URL column</li>
            <li>Click "Start Extraction"</li>
          </ol>

          <Container
            style={{
              paddingBlock: '20px',
              backgroundImage: 'url("/re.png")',
              borderRadius: '12px',
            }}
          >
            <Box
  {...getRootProps()}
  sx={{
    border: '2px dashed #aaa',
    borderRadius: '8px',
    padding: '20px',
    textAlign: 'center',
    backgroundColor: isDragActive ? '#e0f7fa' : '#fafafa',
    marginBottom: '20px',
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '150px',
    cursor: 'pointer',
  }}
>
  <input {...getInputProps()} />
  <Button variant="contained" sx={{ zIndex: 2 ,backgroundColor:'#0082cc !important',textTransform: 'capitalize !important',fontSize: '1.3rem'}}>
    Upload File
  </Button>

  <Typography variant="h6" sx={{ marginTop: '10px' }}>
    Drag & Drop an Excel file here, or click to select
  </Typography>
</Box>





            {fileName && (
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  marginBottom: '20px',
                }}
              >
                <InsertDriveFileIcon
                  sx={{
                    marginRight: '10px',
                    fontSize: '30px',
                    color: '#1C6B58',
                  }}
                />
                <Typography variant="subtitle1">{fileName}</Typography>
              </Box>
            )}

            {headers.length > 0 && (
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  gap: '10px',
                  marginBottom: '20px',
                }}
              >
                <Typography
                  style={{ fontSize: '19px', fontWeight: '600' }}
                  variant="body1"
                  component="label"
                  htmlFor="column-select"
                >
                  Select URL Column:
                </Typography>
                <FormControl sx={{ minWidth: 200 }}>
                  <InputLabel id="column-select-label">URL Column</InputLabel>
                  <Select
                    labelId="column-select-label"
                    id="column-select"
                    value={selectedColumnIndex}
                    label="URL Column"
                    onChange={(e) =>
                      handleColumnSelection(headers[e.target.value])
                    }
                  >
                    {headers.map((header, index) => (
                      <MenuItem key={index} value={index}>
                        {header}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
            )}

            {dataRows.length > 0 && (
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  gap: '10px',
                  marginTop: '20px',
                  
                }}
              >


<Button
  variant="contained"
  onClick={handleStartExtraction}
  sx={{
    textTransform: 'capitalize !important',
    fontSize: {
      xs: '14px !important',  // 0.875rem
      sm: '18px !important',  // 1.125rem
      md: '20px !important',  // 1.25rem
      lg: '22px !important',  // 1.375rem
    },
    backgroundColor: selectedColumnIndex === '' 
      ? '#004e7a !important' 
      : '#0082cc !important',
    color: '#fff !important',
    height: {
      xs: 'auto !important',      // Allow height to adjust based on content
      sm: '40px !important',      // 2.5rem
      md: '44px !important',      // 2.75rem
      lg: '48px !important',      // 3rem
    },
    padding: {
      xs: '8px 16px !important',  // Reduced horizontal padding for better fit
      sm: '8px 16px !important',
      md: '10px 20px !important',
      lg: '12px 24px !important',
    },
    margin: '8px !important',
    width: {
      xs: '100% !important',      // Full width on extra small screens
      sm: 'auto !important',      // Auto width on small and larger screens
    },
    whiteSpace: {
      xs: 'normal !important',    // Allow text to wrap on extra small screens
      sm: 'nowrap !important',    // Prevent wrapping on small and larger screens
    },
    overflow: {
      xs: 'visible !important',   // Show all content on extra small screens
      sm: 'hidden !important',    // Hide overflow on small and larger screens
    },
    textOverflow: {
      xs: 'unset !important',     // No ellipsis on extra small screens
      sm: 'ellipsis !important',  // Add ellipsis on small and larger screens
    },
    lineHeight: {
      xs: '1.2 !important',       // Improved readability for wrapped text
      sm: '1 !important',         // Default line height on larger screens
    },
  }}
>
  Start Extraction
</Button>

<Button
  variant="outlined"
  onClick={handleStopExtraction}
  sx={{
    textTransform: 'capitalize !important',
    fontSize: {
      xs: '14px !important',
      sm: '18px !important',
      md: '20px !important',
      lg: '22px !important',
    },
    backgroundColor: selectedColumnIndex === '' 
      ? '#004e7a !important' 
      : '#0082cc !important',
    color: '#fff !important',
    height: {
      xs: 'auto !important',
      sm: '40px !important',
      md: '44px !important',
      lg: '48px !important',
    },
    padding: {
      xs: '8px 16px !important',
      sm: '8px 16px !important',
      md: '10px 20px !important',
      lg: '12px 24px !important',
    },
    margin: '8px !important',
    width: {
      xs: '100% !important',
      sm: 'auto !important',
    },
    whiteSpace: {
      xs: 'normal !important',
      sm: 'nowrap !important',
    },
    overflow: {
      xs: 'visible !important',
      sm: 'hidden !important',
    },
    textOverflow: {
      xs: 'unset !important',
      sm: 'ellipsis !important',
    },
    lineHeight: {
      xs: '1.2 !important',
      sm: '1 !important',
    },
  }}
>
  Stop
</Button>

<Button
  variant="outlined"
  onClick={handleExportToExcel}
  sx={{
    textTransform: 'capitalize !important',
    fontSize: {
      xs: '14px !important',
      sm: '18px !important',
      md: '20px !important',
      lg: '22px !important',
    },
    backgroundColor: selectedColumnIndex === '' 
      ? '#004e7a !important' 
      : '#0082cc !important',
    color: '#fff !important',
    height: {
      xs: 'auto !important',
      sm: '40px !important',
      md: '44px !important',
      lg: '48px !important',
    },
    padding: {
      xs: '8px 16px !important',
      sm: '8px 16px !important',
      md: '10px 20px !important',
      lg: '12px 24px !important',
    },
    margin: '8px !important',
    width: {
      xs: '100% !important',
      sm: 'auto !important',
    },
    whiteSpace: {
      xs: 'normal !important',
      sm: 'nowrap !important',
    },
    overflow: {
      xs: 'visible !important',
      sm: 'hidden !important',
    },
    textOverflow: {
      xs: 'unset !important',
      sm: 'ellipsis !important',
    },
    lineHeight: {
      xs: '1.2 !important',
      sm: '1 !important',
    },
  }}
>
  Export to Excel
</Button>

              </Box>
            )}

{loading && (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginBlock: '20px',
          }}
        >
          <img
            src="Loading.gif"
            alt="Loading..."
            style={{ width: '50px' }}
          />
          {loadingMessage && (
            <Typography variant="subtitle1" sx={{ marginLeft: '10px' }}>
              {loadingMessage}
            </Typography>
          )}
        </Box>
      )}

            {progress > 0 && (
              <Grid container style={{ marginTop: '20px' }}>
                <Grid item xs={12}>
                  <LinearProgress
                    style={{ height: '24px', borderRadius: '12px' }}
                    variant="determinate"
                    value={progress}
                  />
                  <Typography style={{ color: '#1976d2' }} align="center">
                    {progress}%
                  </Typography>
                </Grid>
              </Grid>
            )}

            {/* Display the stopped message when extraction is stopped */}
            {showStoppedMessage && (
              <Typography
                variant="subtitle1"
                sx={{ marginTop: '10px', color: 'green' }}
              >
                Extraction is stopped
              </Typography>
            )}

            {dataRows.length > 0 && (
              <TableContainer component={Paper} style={{ marginTop: '20px' }}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell
                      style={{
                        color: 'white',
                        backgroundColor: '#0082cc',
                      }}
                      >#</TableCell>
                      <TableCell
                        style={{
                          color: 'white',
                          backgroundColor: '#0082cc',
                        }}
                      >
                        Extracted Email
                      </TableCell>
                      {headers.map((header, index) => (
                        <TableCell key={index}>{header}</TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {dataRows.map((row, rowIndex) => (
                      <TableRow key={rowIndex}>
                        <TableCell>{rowIndex + 1}</TableCell>
                        <TableCell
                          style={{
                            backgroundColor: extractedEmails[rowIndex]?.email
                              ? '#FFEB3B'
                              : 'inherit',
                          }}
                        >
                          {extractedEmails[rowIndex]?.email || ''}
                        </TableCell>
                        {row.map((cell, cellIndex) => (
                          <TableCell key={cellIndex}>{cell || ''}</TableCell>
                        ))}
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            )}
          </Container>
        </section>

        {/* Features Section */}
        <h2
          style={{
            textAlign: 'center',
            color: 'white',
            fontSize: '49px',
          }}
        >
          Features
        </h2>

        <section className="email-extraction-section">
          <div className="container">
            <h2 className="section-title">Transform Your Email Search Efforts!</h2>

            <div style={{ textAlign: 'center' }}>
              <img style={{ width: '222px' }} src="/oho2.png" alt="" />
            </div>

            <p className="section-intro">
              Have you ever found yourself spending countless hours trying to find
              targeted emails for marketing or outreach purposes? If so, you know
              how difficult and time-consuming this process can be. But what if we
              told you there’s an easier and faster solution?
            </p>

            <p className="section-intro">
              With Sazalis' Google Sheets Add-on for extracting emails from Target
              websites, you can transform this tedious process into a seamless and
              efficient experience!
            </p>

            <div className="benefits">
              <h3 className="benefits-title">Why Choose Sazalis?</h3>

              <div style={{ textAlign: 'center' }}>
                <img style={{ width: '197px' }} src="/yah2.png" alt="" />
              </div>

              <ul className="benefits-list">
                <li className="benefit-item">
                  <strong>Speed and Ease</strong>: Instead of spending hours
                  manually searching for emails, you can simply extract a list of
                  websites whose field you already know. This way, you can get
                  targeted emails more accurately and quickly.
                </li>

                <li className="benefit-item">
                  <strong>Accuracy and Effectiveness</strong>: Our tool allows you
                  to obtain direct emails from selected websites, ensuring that the
                  targeted emails are actually related to your area of interest or
                  business.
                </li>

                <li className="benefit-item">
                  <strong>User-Friendly</strong>: You don't need any specialized
                  skills to use this tool; it’s designed to be intuitive and
                  user-friendly. All you have to do is enter your list of
                  websites, and let the application do the rest.
                </li>
              </ul>
            </div>
          </div>
        </section>

        <p style={{ color: 'white', textAlign: 'center' }}>
          &copy; 2024 Sazalis.com. All rights reserved.
        </p>
      </div>

      {/* Add Snackbar component */}
      {/* Add Snackbar component */}
      {/* Add Snackbar component */}
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={() => setOpenSnackbar(false)}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }} // Move Snackbar to the top
      >
        <Alert
          onClose={() => setOpenSnackbar(false)}
          severity="error" // Use 'info' for blue color
          sx={{
            width: '100%',
            maxWidth: '500px',
            fontSize: '1.2rem',
            textAlign: 'center',
            padding: '16px', // Adjusted padding
            boxSizing: 'border-box',
            display: 'flex',
            alignItems: 'center',
          }}
          componentsProps={{
            icon: {
              sx: {
                fontSize: '2rem',    // Increase icon size
                marginRight: '8px',  // Add space between icon and text
              },
            },
          }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>


    </>
  );



}

export default App;
